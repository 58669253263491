import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/SEO"
import Img from "gatsby-image"
import TitleBox from "../components/titleBox"
import BlockContentPrimary from "../components/BlockContentPrimary"

const EventListItem = ({ fixedImage, eventTitle = "Event Title" }) => {
  return (
    <li className="about__events-list-item">
      <Img fixed={fixedImage} />
      <p className="about__eventsTitle">{eventTitle}</p>
    </li>
  )
}

const StatsListItem = ({
  circleImage,
  detail = "###",
  statTitle = "Some stats",
}) => {
  return (
    <li className="about__statistics-list-item">
      <div className="about__dataContainer relative grid justify-center items-center">
        <div className="about__circleIcon">
          <Img fixed={circleImage} />
        </div>
        <div className="about__statsData">{detail}</div>
      </div>
      <p className="about__statsTitle">{statTitle}</p>
    </li>
  )
}

const About = ({ data }) => {
  const participants = data.participants.edges

  const featherImage = data.feather.childImageSharp.fixed
  const circleImage = data.circle.childImageSharp.fixed
  const { aboutPage } = data
  const { title, _rawDescription: rawDescription, headerImage } = aboutPage
  const fluid = headerImage?.asset?.fluid || null

  const eventListItem_default = [
    { title: "Event Feature One", image: featherImage },
    { title: "Event Feature Two", image: featherImage },
    { title: "Event Feature Three", image: featherImage },
    { title: "Event Feature Four", image: featherImage },
  ]

  const statsItem_default = [
    { title: "Years Running", detail: "05" },
    { title: "Hosting Councils", detail: `${participants.length}` },
    { title: "Workshops", detail: "80" },
    { title: "Attendees", detail: "150+" },
  ]

  const aboutDetails_default =
    "NOFNEC ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis."
  // console.log("PARTICIPANTS", participants)

  return (
    <Layout>
      <section className="about__whole-container">
        <SEO title="About" />
        <TitleBox title={title} background={fluid}>
          <div className="pageContent">
            <BlockContentPrimary blockData={rawDescription} />
          </div>
        </TitleBox>

        <article className="flex">
          <div className=" w-full grid grid-flow-row md:grid-cols-2 col-gap-2 my-2">
            <Img fluid={data.bg.childImageSharp.fluid} className="h-100" />
            <Img fluid={data.bg2.childImageSharp.fluid} className="h-100" />
          </div>
        </article>
        {/* Temporarily disable statistics container */}
        {null && (
          <article className="about__statistics-container">
            {/* Background image courtesy of Paul Gilmore on Unsplash https://unsplash.com/@paulgilmore_ */}
            <BackgroundImage
              Tag="div"
              className="about__statistics-container-img"
              fluid={data.bg3.childImageSharp.fluid}
            >
              <div className="about__statistics-container-wrapper">
                <div className="about__statistics-container-top">
                  <ul className="flex justify-around flex-wrap">
                    {statsItem_default.map(item => (
                      <StatsListItem
                        circleImage={circleImage}
                        detail={item.detail}
                        statTitle={item.title}
                      />
                    ))}
                  </ul>
                </div>
                <div className="about__statistics-container-middle">
                  <p>{aboutDetails_default}</p>
                </div>
                <div className="about__statistics-container-bottom">
                  <ul className="flex justify-around flex-wrap">
                    {eventListItem_default.map(item => (
                      <EventListItem
                        fixedImage={item.image}
                        eventTitle={item.title}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </BackgroundImage>
          </article>
        )}
        {/* <article className="about__footer-images-container">
        <BackgroundImage
          Tag="div"
          className="about__footer-background2"
          fluid={data.bg2.childImageSharp.fluid}
        ></BackgroundImage>
        <span className="about__footer-images-container-divider"></span>
        <BackgroundImage
          Tag="div"
          className="about__footer-background1"
          fluid={data.bg.childImageSharp.fluid}
        ></BackgroundImage>
      </article> */}
        <article className="flex">
          <div className="w-full grid grid-flow-row md:grid-cols-2 col-gap-2 my-2">
            <Img fluid={data.bg4.childImageSharp.fluid} className="h-100" />
            <Img fluid={data.bg5.childImageSharp.fluid} className="h-100" />
          </div>
        </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    bg: file(relativePath: { eq: "DSC_0314.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bg2: file(relativePath: { eq: "DKD0302.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bg3: file(relativePath: { eq: "AdobeStock_68922444.jpeg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bg4: file(relativePath: { eq: "DKD0084.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bg5: file(relativePath: { eq: "DKD0291.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feather: file(relativePath: { eq: "Feather-Icon.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    circle: file(relativePath: { eq: "Empty-Circle-Icon.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    participants: allSanityParticipant(filter: { active: { eq: true } }) {
      edges {
        node {
          id
          title
        }
      }
    }
    aboutPage: sanityAbout {
      title
      _rawDescription
      headerImage {
        asset {
          fluid(maxWidth: 1800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default About
